<template>
  <iModal
    v-model="show"
    :mask-closable="false"
    @on-cancel="cancel"
    footer-hide
    width="250"
    class-name="my-show"
  >
    <div id="qrcode" class="qrcode"></div>
    <p
      style="
        font-size: 18px;
        color: #333;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      "
    >
      微信扫描 查看详情
    </p>
  </iModal>
</template>
<script>
import QRCode from "qrcodejs2";
import { mapGetters } from "vuex";
import config from "@assets/config.json"; // api 请求json

export default {
  name: "showMoblie", // 显示手机版扫码
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
  },
  mounted() {
    if (this.exhibitionId) {
      this.getQrCode();
    }
  },
  created() {},
  methods: {
    open() {
      this.show = true;
    },
    cancel() {
      this.show = false;
    },
    getQrCode() {
      let url = `${config.mobileUrl}/home/${this.exhibitionId}`;
      let qrcode = new QRCode("qrcode", {
        width: 180,
        height: 180,
        text: url, // 二维码内容
        correctLevel: 3,
      });
    },
  },
};
</script>
<style lang="scss">
.my-show {
  img {
    margin: 20px auto 10px;
  }
}
</style>
